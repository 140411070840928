import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PaymentFailed = () => {
  const [role, setRole] = useState(localStorage.getItem("Role"));

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <!-- <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"> --> */}
            <a href="/patron-home" className="site-logo">
              <img src={require("../../assets/images/thcm-logo-red.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to enjoy unique
              cuisines, healthy regional foods by many who enjoy cooking and
              eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="col-12 not_found">
              <p>Payment failed. Please try again later!</p>
            </div>
            {role === "Patron" ? (
              <div className="col-12 p-0">
                <button
                  className="btn w-100"
                  onClick={() => navigate("/patron-home")}
                >
                  Go to home
                </button>
              </div>
            ) : (
              <div className="col-12 p-0">
                <button
                  className="btn w-100"
                  onClick={() => navigate("/user-login")}
                >
                  Go to Login
                </button>
              </div>
            )}
          </form>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
            Homey-FOODS is a service that brings Meal Hosts and Patrons
            together. Homey-FOODS makes no representations, endorsements. or
            warranties of any kind whatsoever of Meal Hosts and Customers. Both
            parties agree to comply to local government laws, be of legal age
            and Homey-FOODS shall not be held responsible from disputes, damages
            and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div className="social-media">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
};
