import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import phoneBg from "../../assets/images/bg-color.jpg";

export const MealHostLandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("Role", "MealHost");
  });

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <!-- <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"> --> */}
            <a href="#" className="site-logo">
              <img src={require("../../assets/images/thcm-logo-red.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to enjoy unique
              cuisines, healthy regional foods by many who enjoy cooking and
              eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>

      <main style={{ padding: "0.01rem 0", backgroundColor: "#00ff00" }}>
        <section
          style={{
            background: "#0e9b44",
            padding: "30px 0",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "22px",
              color: "#fff",
              margin: "0",
              fontWeight: "900",
            }}
          >
            WELCOME MEAL HOST- PLEASE REGISTER
          </h2>
        </section>
        <div className="container">
          <div
            style={{
              border: "3px solid #eddedc",
              backgroundColor: "#111",
              borderRadius: "35px",
              padding: "9px 9px 9px 9px",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "243px",
              textAlign: "center",
              marginTop: "40px",
              position: "relative",
              marginBottom: "20px",
              background: "#111",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                content: "",
                left: "0px",
                margin: "auto",
                right: "0px",
                width: "100px",
                height: "28px",
                background: "#111",
                borderRadius: "0px 0px 16px 16px",
              }}
            ></div>
            <div
              style={{
                background: `url(${phoneBg}) no-repeat 19% / cover`,
                padding: "51px 14px 32px",
                borderRadius: "24px",
                height: "100%",
              }}
            >
              <h1
                style={{
                  borderBottom: "2px solid#000",
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#000",
                  fontWeight: "900",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                MEAL HOSTS
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#000",
                  fontWeight: "700",
                  paddingBottom: "6px",
                }}
              >
                Use culinary skills offer regional cuisines and become
                financially successful.{" "}
              </p>
              <img
                style={{
                  width: "139px",
                  margin: "0 auto",
                  borderRadius: "50%",
                  border: "4px solid#fff",
                  marginBottom: "6px",
                }}
                src={require("../../assets/images/mobile_banner.png")}
                alt="mobile banner"
              />
            </div>
            {/* <!-- <div className="button_phone"></div> --> */}
          </div>
          <div style={{ paddingBottom: "2rem" }}>
            <h3
              style={{
                fontSize: "28px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "30px",
              }}
            >
              Become a Meal Host
            </h3>
            <a
              href="/mealhost-registration"
              style={{
                borderRadius: "5px",
                display: "block",
                backgroundColor: "#20400f",
                padding: "15px 50px",
                textTransform: "uppercase",
                color: "#fff",
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "900",
                textAlign: "center",
              }}
            >
              REGISTER
            </a>
          </div>
        </div>
        {/* <div className="alreay-account-section">
          <p>
            ALREADY HAVE AN ACCOUNT ? &nbsp;
            <a
              onClick={() => {
                localStorage.clear();
                navigate("/user-login");
              }}
              style={{ cursor: "pointer", color: "white" }}
            >
              LOG IN
            </a>
          </p>
        </div> */}
      </main>
      <footer>
        <div className="container">
          <p className="pb-2">DISCLAIMER</p>
          <p>
            Homey-FOODS is a service that brings Meal Hosts and Patrons
            together. Homey-FOODS makes no representations, endorsements. or
            warranties of any kind whatsoever of Meal Hosts and Customers. Both
            parties agree to comply to local government laws, be of legal age
            and Homey-FOODS shall not be held responsible from disputes, damages
            and controversies.
          </p>
          <div className="footer_bottom text-center">
            <a href="http://www.homey-foods.com" className="site_link my-3">
              www.homey-foods.com
            </a>
            <div className="social-media">
              <ul>
                <li>
                  <a href="#" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fab fa-linkedin"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <p>© 2023 Homey-FOODS</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
