import React from "react";
import { NavLink } from "react-router-dom";

export const ThcmFooter = () => {
  return (
    <footer>
      <div className="ft-color">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="footer-content">
                <h4>Disclaimer</h4>
                <p>
                  Homey-FOODS is a service that brings Meal Hosts and Patrons
                  together. Homey-FOODS makes no representations, endorsements.
                  or warranties of any kind whatsoever of Meal Hosts and
                  Customers. Both parties agree to comply to local government
                  laws, be of legal age and Homey-FOODS shall not be held
                  responsible from disputes, damages and controversies.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4 text-end">
              <div className="footer-info">
                <a href="https://homey-foods.com/">
                  <img
                    src={require("../../assets/images/thcm-logo-red.png")}
                    alt="logo"
                    width="100px"
                  />
                </a>
                {/* <NavLink to="/enquiry" className="contact-btn">
                      Contact Us <i className="fa fa-angle-right"></i>
                    </NavLink> */}
                <p>
                  71 Pilgrim Avenue
                  <br /> Chevy Chase, MD 20815
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center mt-4">
            <div className="col-12">
              <div className="copyright">
                <p>www.homey-foods.com</p>
                <p>All rights reserved to Homey-FOODS.</p>
                <div className="footer_bottom">
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <li style={{ marginRight: "10px" }}>
                      <a href="#" target="_blank">
                        <i
                          className="fab fa-facebook-f"
                          style={{ color: "#4267B2" }}
                        ></i>
                      </a>
                    </li>
                    <li style={{ marginRight: "10px" }}>
                      <a href="#" target="_blank">
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          height="15"
                          fill="black"
                          width="16"
                          viewBox="0 0 512 512"
                          style={{ position: "relative", top: "-2px" }}
                        >
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                      </a>
                    </li>
                    <li style={{ marginRight: "10px" }}>
                      <a href="#" target="_blank">
                        <i
                          className="fab fa-linkedin"
                          style={{ color: "#0A66C2" }}
                        ></i>{" "}
                      </a>
                    </li>
                    <li style={{ marginRight: "10px" }}>
                      <a href="#" target="_blank">
                        <i
                          className="fab fa-instagram"
                          style={{ color: "#C13584" }}
                        ></i>
                      </a>
                    </li>
                  </ul>
                  <p>Homey-FOODS © 2023 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
