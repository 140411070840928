import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import { passwordValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";

export const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <!-- <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"> --> */}
            <a href="/" className="site-logo">
              <img src={require("../../assets/images/thcm-logo-red.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to enjoy unique
              cuisines, healthy regional foods by many who enjoy cooking and
              eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main style={{ padding: "0rem" }}>
        <div
          style={{
            backgroundColor: "#0e9b44",
            padding: "20px",
            marginBottom: "40px",
            position: "relative",
          }}
        >
          {/* <SideMenu /> */}
          {/* <h2>Easy and Fast Access to Homey-FOODS</h2> */}
          <span
            style={{
              display: "block",
              fontWeight: "900",
              color: "#fff",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            CHANGE PASSWORD
          </span>
        </div>
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={passwordValidation}
            initialValues={{
              oldPassword: "",
              confirmPassword: "",
              newPassword: "",
            }}
            onSubmit={(values, { resetForm }) => {
              axios
                .post(mainServerAppUrl + "/user/change-password", values)
                .then((data) => {
                  // console.log(data);
                  toast.success(data?.data?.message, {
                    id: "Success",
                    duration: 3000,
                  });
                  resetForm();
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(error?.response?.data?.message, {
                    id: "Failure",
                    duration: 3000,
                  });
                });
            }}
          >
            {({ errors, status, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <Field
                      type="password"
                      name="oldPassword"
                      placeholder="Old Password"
                      className={
                        "w-100 form-control" +
                        (errors.oldPassword && touched.oldPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="form_invalid"
                      style={{ paddingLeft: "0px" }}
                    />
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                      className={
                        "w-100 form-control" +
                        (errors.newPassword && touched.newPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <div
                      className="p-viewer-1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={
                          showPassword
                            ? "fa-solid fa-eye fa-lg"
                            : "fa-sharp fa-solid fa-eye-slash fa-lg"
                        }
                      ></i>
                    </div>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="form_invalid"
                      style={{ paddingLeft: "0px" }}
                    />
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className={
                        "w-100 form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="form_invalid"
                      style={{ paddingLeft: "0px" }}
                    />
                  </div>
                </div>
                <div className="col-12 p-2">
                  <button
                    type="submit"
                    value="CHANGE PASSWORD"
                    className="btn w-100"
                  >
                    CHANGE PASSWORD
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
            Homey-FOODS is a service that brings Meal Hosts and Patrons
            together. Homey-FOODS makes no representations, endorsements. or
            warranties of any kind whatsoever of Meal Hosts and Customers. Both
            parties agree to comply to local government laws, be of legal age
            and Homey-FOODS shall not be held responsible from disputes, damages
            and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div
            className="social-media"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
};
