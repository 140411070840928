import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuthUser } from "../Authentication/authHelper";


export const Header = () => {
    const navigate = useNavigate();



  return (
    <>
      <header>
        <div className="header-info">
          <div className="left-part">
            <Link onClick={(e)=>{
                e.preventDefault();
                if (getAuthUser().role === "Patron") {
                    navigate("/patron-home");
                  } else if (getAuthUser().role === "MealHost") {
                    navigate("/mealhost-home");
                  } else {
                    navigate("/");
                  }
                }} 
                 className="site-logo">
              <img src={require("../../assets/images/thcm-logo-red.png")} alt=""/>
            </Link>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p style={{textAlign: "justify"}}>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p style={{textAlign: "justify"}}>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
    </>
  )
};

